import { fetchProjectsByBizContractorId } from "repositories/owner/Project";
import { fetchUserByCompanyIdUserId } from "repositories/owner/User";
import { Project } from "@shared/types/entities/owner/Project";
import { FireTimestamp } from "utils/firebaseUtil";

export interface UserInfo {
  userName: string;
  userTel: string;
  userEmail: string;
}

export type ProjectWithUser = Project<FireTimestamp> & UserInfo;

export interface BuildingInfo {
  buildingId: string;
  buildingName: string;
  projectWithUsers: ProjectWithUser[];
}

/**ユーザーを取得し、案件と合わせて返す */
async function fetchProjectWithUser(project: Project): Promise<ProjectWithUser> {
  const user = await fetchUserByCompanyIdUserId(project.companyId, project.ownerUid || "");

  const userInfo: UserInfo = {
    userName: `${user.staffLastName} ${user.staffFirstName}`,
    userTel: user.tel,
    userEmail: user.email,
  };

  return Object.assign(project, userInfo);
}

export async function fetchBuildingInfoList(bizContractorId: string, ownerIdToken: string): Promise<BuildingInfo[]> {
  let buildingInfoList: BuildingInfo[] = [];

  try {
    // 工事業者が請け負っている全ての案件を取得
    const projects = await fetchProjectsByBizContractorId(bizContractorId);
    if (projects.length === 0) {
      return [];
    }

    for (const project of projects) {
      if (!project.companyId || !project.buildingId) {
        continue;
      }

      const projectWithUser = await fetchProjectWithUser(project);

      // 建物IDごとにbuildingInfoを振り分ける
      const buildingInfo = buildingInfoList.find((buildingInfo) => buildingInfo.buildingId === project.buildingId);
      if (buildingInfo) {
        buildingInfo.projectWithUsers.push(projectWithUser);
      } else {
        const buildingInfo: BuildingInfo = {
          buildingId: project.buildingId,
          buildingName: project.buildingName ?? "",
          projectWithUsers: [projectWithUser],
        };

        buildingInfoList.push(buildingInfo);
      }
    }
  } catch (e) {
    console.log(e);
  }

  return buildingInfoList;
}
