import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { getFormattedDate } from "../../../utils";
import { StatusCard } from "components/StatusCard/StatusCard";
import { UserInfoDialog } from "./UserInfoDialog";
import { BuildingInfo, ProjectWithUser, UserInfo } from "../useCases/fetchBuildingInfoList";
import { FireTimestamp } from "utils/firebaseUtil";
import { ConstructionTypeCard } from "components/ConstructionTypeCard/ConstructionTypeCard";

export const BuildingArea = (buildingInfo: BuildingInfo): JSX.Element => {
  const isPC = useMediaQuery("(min-width:640px)");

  return <>{isPC ? <PCDisplay {...buildingInfo} /> : <SPDisplay {...buildingInfo} />}</>;
};

const PCDisplay = ({ buildingName, projectWithUsers }: BuildingInfo): JSX.Element => {
  const [userInfoDialogOpen, setUserInfoDialogOpen] = useState<boolean>(false);
  const [userInfoValue, setUserInfoValue] = useState<UserInfo>();

  const handleNavigateToProjectDetail = (companyId: string, projectId: string): void => {
    const url = `/companies/${companyId}/projects/${projectId}`;
    window.open(url, "_blank");
  };

  const handleUserInfoDialogOpen = (projectWithUser: ProjectWithUser): void => {
    const userInfo: UserInfo = projectWithUser;
    setUserInfoValue(userInfo);
    setUserInfoDialogOpen(true);
  };

  const handleOwnerInfoDialogClose = (): void => {
    setUserInfoDialogOpen(false);
  };

  /**工事完了希望日 */
  const dueDate = (projectWithUser: ProjectWithUser): FireTimestamp | undefined => {
    if (projectWithUser.requestDueDate) {
      return projectWithUser.requestDueDate;
    }

    if (projectWithUser.dueDate) {
      return projectWithUser.dueDate;
    }
  };

  return (
    <Box className="p-1 mb-4">
      <Box className="flex flex-col mb-2">
        <Box className="font-bold text-remodela-green">{buildingName}</Box>
        <Box className="text-xs">{`${projectWithUsers[0]?.state}${projectWithUsers[0]?.address} / ${projectWithUsers[0]?.companyName}`}</Box>
      </Box>

      <TableContainer component={Paper}>
        <Table size="small" padding="none" aria-label="customized table" className={`min-w-[1400px]`}>
          <TableHead>
            <TableRow>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[100px]">案件No.</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[120px]">部屋番号</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[80px]">工事タイプ</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[120px]">ステータス</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">受付日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">退去立会日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">契約終了日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">見積日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">受注日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">工事完了日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">更新日</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[110px]">担当者情報</TableCell>
              <TableCell className="bg-lightGray text-darkGray text-center text-sm w-[100px]">詳細</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {projectWithUsers.map((projectWithUser) => (
              <TableRow key={projectWithUser.id} className="even:bg-offWhite">
                <TableCell className="text-center text-sm w-[100px]">{Number(projectWithUser.no)}</TableCell>
                <TableCell className="text-center text-sm w-[120px]">{projectWithUser.roomNo}</TableCell>
                <TableCell className="text-center text-sm w-[80px]">
                  <Box className="flex justify-center">
                    <ConstructionTypeCard constructionType={projectWithUser.constructionType} />
                  </Box>
                </TableCell>
                <TableCell className="text-center text-sm w-[120px]">
                  <StatusCard status={projectWithUser.status} />
                </TableCell>
                <TableCell className="text-center text-sm w-[110px]">
                  {getFormattedDate(projectWithUser.createdAt)}
                </TableCell>

                <TableCell className="text-center text-sm w-[110px]">
                  {projectWithUser.needFinalInspection && `${getFormattedDate(projectWithUser.finalInspection.date)}`}
                </TableCell>
                <TableCell className="text-center text-sm w-[110px]">
                  {projectWithUser.needFinalInspection &&
                    `${getFormattedDate(projectWithUser.finalInspection.leaseEndDate)}`}
                </TableCell>

                <TableCell className="text-center text-sm w-[110px]">
                  {getFormattedDate(projectWithUser.estimatedAt)}
                </TableCell>
                <TableCell className="text-center text-sm w-[110px]">
                  {getFormattedDate(projectWithUser.offeredAt)}
                </TableCell>
                <TableCell className="text-center text-sm w-[110px]">
                  {getFormattedDate(dueDate(projectWithUser))}
                </TableCell>
                <TableCell className="text-center text-sm w-[110px]">
                  {getFormattedDate(projectWithUser.updatedAt)}
                </TableCell>
                <TableCell className="text-center text-sm w-[110px]">
                  <IconButton size="small" onClick={() => handleUserInfoDialogOpen(projectWithUser)}>
                    <TextSnippetOutlinedIcon />
                  </IconButton>
                </TableCell>
                <TableCell className="py-2 text-center text-sm w-[100px]">
                  <Button
                    size="small"
                    variant="outlined"
                    className="text-remodela-green border-remodela-green rounded-xl font-bold"
                    onClick={() => handleNavigateToProjectDetail(projectWithUser.companyId, projectWithUser.id!)}
                  >
                    詳細
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <UserInfoDialog userInfoValue={userInfoValue} open={userInfoDialogOpen} onClose={handleOwnerInfoDialogClose} />
    </Box>
  );
};

const SPDisplay = ({ buildingName, projectWithUsers }: BuildingInfo): JSX.Element => {
  const [userInfoDialogOpen, setUserInfoDialogOpen] = useState<boolean>(false);
  const [userInfoValue, setUserInfoValue] = useState<UserInfo>();

  const handleNavigateToProjectDetail = (companyId: string, projectId: string) => {
    const url = `/companies/${companyId}/projects/${projectId}`;
    window.open(url, "_blank");
  };

  const handleUserInfoDialogOpen = (projectWithUser: ProjectWithUser): void => {
    const userInfo: UserInfo = projectWithUser;
    setUserInfoValue(userInfo);
    setUserInfoDialogOpen(true);
  };

  const handleOwnerInfoDialogClose = (): void => {
    setUserInfoDialogOpen(false);
  };

  /**工事完了希望日 */
  const dueDate = (projectWithUser: ProjectWithUser): FireTimestamp | undefined => {
    if (projectWithUser.requestDueDate) {
      return projectWithUser.requestDueDate;
    }

    if (projectWithUser.dueDate) {
      return projectWithUser.dueDate;
    }
  };

  return (
    <Box className="mb-4">
      {/* 建物名 */}
      <Box className="text-sm font-bold text-remodela-green">{buildingName}</Box>
      {/* 住所, 施主名 */}
      <Box className="flex my-1">
        <Box className="text-xs">{`${projectWithUsers[0]?.state}${projectWithUsers[0]?.address} / ${projectWithUsers[0]?.companyName}`}</Box>
      </Box>
      {projectWithUsers.map((projectWithUser) => {
        return (
          <Paper key={projectWithUser.id} elevation={3} className="flex flex-col my-1 p-2">
            <Box className="flex justify-between items-center">
              <Box className="flex">
                <Box className="text-sm font-bold pr-2">{projectWithUser.roomNo}</Box>
                <ConstructionTypeCard constructionType={projectWithUser.constructionType} />
              </Box>
              <Box className="px-2">
                <StatusCard status={projectWithUser.status} />
              </Box>
            </Box>

            <Divider light className="my-1" />

            <Box className="flex">
              {/* 案件情報 */}
              <Box className="flex w-full">
                <Box className="flex grow">
                  {/* 日付関連 */}
                  <Grid container className="text-xs w-full">
                    <Grid xs={6} className="flex items-center">
                      <Box className="min-w-[65px]">受付日</Box>
                      <Box>{getFormattedDate(projectWithUser.createdAt)}</Box>
                    </Grid>
                    <Grid xs={6} className="flex items-center">
                      <Box className="min-w-[65px]">退去立会日</Box>
                      <Box>
                        {projectWithUser.needFinalInspection &&
                          `${getFormattedDate(projectWithUser.finalInspection.date)}`}
                      </Box>
                    </Grid>
                    <Grid xs={6} className="flex items-center">
                      <Box className="min-w-[65px]">契約終了日</Box>
                      <Box>
                        {projectWithUser.needFinalInspection &&
                          `${getFormattedDate(projectWithUser.finalInspection.leaseEndDate)}`}
                      </Box>
                    </Grid>
                    <Grid xs={6} className="flex items-center">
                      <Box className="min-w-[65px]">見積日</Box>
                      <Box>{getFormattedDate(projectWithUser.estimatedAt)}</Box>
                    </Grid>
                    <Grid xs={6} className="flex items-center">
                      <Box className="min-w-[65px]">受注日</Box>
                      <Box>{getFormattedDate(projectWithUser.offeredAt)}</Box>
                    </Grid>
                    <Grid xs={6} className="flex items-center">
                      <Box className="min-w-[65px]">工事完了日</Box>
                      <Box>{getFormattedDate(dueDate(projectWithUser))}</Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* 担当者 */}
                <Box className="flex justify-center items-center">
                  <IconButton onClick={() => handleUserInfoDialogOpen(projectWithUser)}>
                    <AccountCircleIcon fontSize="large" />
                  </IconButton>
                </Box>
                {/* 詳細ページ遷移 */}
                <Box className="flex justify-center items-center">
                  <IconButton
                    onClick={() => handleNavigateToProjectDetail(projectWithUser.companyId, projectWithUser.id!)}
                  >
                    <NavigateNextIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Paper>
        );
      })}
      <UserInfoDialog userInfoValue={userInfoValue} open={userInfoDialogOpen} onClose={handleOwnerInfoDialogClose} />
    </Box>
  );
};
