import { Dialog, Grid } from "@mui/material";
import { type UserInfo } from "../useCases/fetchBuildingInfoList";

export type UserInfoDialogProps = {
  userInfoValue?: UserInfo;
  open: boolean;
  onClose: () => void;
};

export const UserInfoDialog = ({ userInfoValue, open, onClose }: UserInfoDialogProps): JSX.Element => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid className="grow p-5" container spacing={2}>
        <Grid item xs={4}>
          担当者
        </Grid>
        <Grid item xs={8}>
          <div className="font-bold">{userInfoValue?.userName}</div>
        </Grid>
        <Grid item xs={4}>
          電話番号
        </Grid>
        <Grid item xs={8}>
          <div className="font-bold">{userInfoValue?.userTel}</div>
        </Grid>
        <Grid item xs={4}>
          メールアドレス
        </Grid>
        <Grid item xs={8}>
          <div className="font-bold">{userInfoValue?.userEmail}</div>
        </Grid>
      </Grid>
    </Dialog>
  );
};
