import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "@nanostores/react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { ja } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from "@mui/material";

import { MainLayout } from "components/Layout/MainLayout";
import { BizConstractorStore, OwnerIdTokenStore } from "store/nanostores/contractorInfo";
import { fetchBizContractorByContractorId } from "repositories/owner/BizContractor";
import { fetchCompaniesByBizContractorId } from "repositories/owner/Company";
import { fetchUsersByCompanyId } from "repositories/owner/User";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { Company } from "@shared/types/entities/owner/Company";
import { User } from "@shared/types/entities/owner/User";
import { BizContractor } from "@shared/types/entities/owner/BizContractor";
import { ResponsePMBuilding, ResponsePMUnit, getPMBuildings, getPMUnits } from "../api/pmAPI";
import { RequestParams, createProject } from "../api/createProject";

const schema = z.object({
  constructionType: z.enum(["原復", "修繕"]),
  company: z.object({
    id: z.string().min(1, "選択してください"),
    name: z.string().min(1, "会社名が無効です"),
  }),
  userId: z.string().min(1, "選択してください"),
  building: z.object({
    id: z.string().min(1, "選択してください"),
    address: z.string().min(1, "住所が無効です"), // 市区町村以降の住所全て
    autoLockNo: z.string(),
    name: z.string().min(1, "建物名が無効です"),
    existsElevator: z.boolean(),
    isAutoLock: z.boolean(),
    state: z.string().min(1, "都道府県が無効です"),
    type: z.string().nullable(),
  }),
  unitName: z.string(),
  placeOfKey: z.string().min(1, "入力してください"),
  needFinalInspection: z.boolean(),
  finalInspection: z.object({
    date: z.date().nullable(), // 立会日時
    leaseEndDate: z.date().nullable(), // 契約終了日
    phoneNumber: z.string(),
    residentName: z.string(), // 入居者氏名
  }),
  dueDate: z.date().nullable(), // 工事完了希望日
  placeOfParking: z.string().nullable(), // 無償利用が可能な駐車場の場所
  request: z.string().nullable(), // その他ご要望
});

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  constructionType: "原復",
  company: { id: "", name: "" },
  userId: "",
  building: {
    id: "",
    address: "",
    autoLockNo: "",
    name: "",
    existsElevator: false,
    isAutoLock: false,
    state: "",
    type: null,
  },
  unitName: "",
  placeOfKey: "",
  needFinalInspection: false,
  finalInspection: {
    date: null,
    leaseEndDate: null,
    phoneNumber: "",
    residentName: "",
  },
  dueDate: null,
  placeOfParking: null,
  request: null,
};

export const CreateProject = (): JSX.Element => {
  const [companies, setCompanies] = useState<Company[]>();
  const [bizContractor, setBizContractor] = useState<BizContractor>();
  const [pmBuildings, setPMBuildings] = useState<ResponsePMBuilding[]>([]);
  const [pmUnits, setPMUnits] = useState<ResponsePMUnit[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const { showSnackbar } = useSnackbar();
  const { setBackdrop } = useBackdrop();
  const navigate = useNavigate();

  const { bizContractorId } = useStore(BizConstractorStore.IDMap);
  const ownerIdToken = useStore(OwnerIdTokenStore.ownerIdToken);

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
  } = useForm<FormValues>({ resolver: zodResolver(schema), defaultValues });
  const formData = watch();

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async (): Promise<void> => {
      if (bizContractorId) {
        const [bizContractor, companies] = await Promise.all([
          fetchBizContractorByContractorId(bizContractorId),
          fetchCompaniesByBizContractorId(bizContractorId),
        ]);

        if (isCancelled) {
          return;
        }

        setBizContractor(bizContractor);
        setCompanies(companies);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [bizContractorId]);

  /**依頼元選択 */
  const handleOnChangeCompany = async (companyId: string): Promise<void> => {
    // データの初期化
    const currentValues = getValues();
    reset({
      ...currentValues,
      userId: defaultValues.userId,
      building: defaultValues.building,
      unitName: defaultValues.unitName,
    });

    try {
      setBackdrop(true);

      const [users, resPMBuildings] = await Promise.all([
        fetchUsersByCompanyId(companyId),
        getPMBuildings(ownerIdToken, companyId),
      ]);

      setUsers(users);
      setPMBuildings(resPMBuildings);
      setPMUnits([]);
    } catch (e) {
      showSnackbar("データの取得に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  /**建物名選択 */
  const handleOnChangeBuilding = async (pmBuildingId: string): Promise<void> => {
    try {
      setBackdrop(true);

      const currentValues = getValues();
      reset({
        ...currentValues,
        unitName: defaultValues.unitName,
      });

      const resPMUnits = await getPMUnits(ownerIdToken, currentValues.company.id, pmBuildingId);
      setPMUnits(resPMUnits);
    } catch (e) {
      showSnackbar("部屋情報の取得に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  const onSubmit = async (data: FormValues): Promise<void> => {
    const user = users.find((user) => user.id === data.userId);
    if (!user) {
      showSnackbar("依頼者名が不正です", "error");
      return;
    }

    const params: RequestParams = {
      address: data.building.address,
      autoLockNo: data.building.autoLockNo,
      bizContractorId,
      buildingId: data.building.id,
      buildingName: data.building.name,
      companyId: data.company.id,
      companyName: data.company.name,
      constructionType: data.constructionType,
      dueDate: data.dueDate?.toISOString() || null,
      existsElevator: data.building.existsElevator,
      finalInspection: {
        date: data.finalInspection.date?.toISOString() || null,
        leaseEndDate: data.finalInspection.leaseEndDate?.toISOString() || null,
        phoneNumber: data.finalInspection.phoneNumber,
        residentName: data.finalInspection.residentName,
      },
      floorPlanImgType: "",
      floorPlanImgUrl: "",
      isAutoLock: data.building.isAutoLock,
      needFinalInspection: data.needFinalInspection,
      ownerUid: data.userId,
      pic: user.staffLastName + user.staffFirstName,
      placeOfKey: data.placeOfKey,
      placeOfParking: data.placeOfParking ?? "",
      projectName: data.building.name,
      request: data.request ?? "",
      roomNo: data.unitName,
      state: data.building.state,
      status: "受付中",
      witnessDocuments: [],
    };

    try {
      setBackdrop(true);
      await createProject(params);
      showSnackbar("案件登録が完了しました", "success");
      navigate("/");
    } catch (e) {
      showSnackbar("案件登録に失敗しました", "error");
    } finally {
      setBackdrop(false);
    }
  };

  if (!companies) {
    return (
      <>
        <MainLayout title="案件登録">
          <Box className="flex justify-center m-6" />
        </MainLayout>
      </>
    );
  }

  return (
    <>
      <MainLayout title="案件登録">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="flex justify-center m-6">
            <Paper className="p-3 w-full sm:w-3/5">
              <Box className="flex justify-center py-4 text-xl font-bold text-remodela-green">案件登録</Box>

              {/* 依頼先 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="依頼先" required />
                <Box>{bizContractor?.name}</Box>
              </Box>
              <Divider className="my-2" />

              {/* 工事タイプ */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="工事タイプ" required />
                <Controller
                  name="constructionType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} row onChange={(e) => field.onChange(e.target.value)}>
                      <FormControlLabel
                        value="原復"
                        control={<Radio className="text-remodela-green" />}
                        label="原状回復工事"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="修繕"
                        control={<Radio className="text-remodela-green" />}
                        label="入居中修繕工事"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
              <Divider className="my-2" />

              {/* 依頼元 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="依頼元" required />
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      select
                      error={!!errors.company?.id}
                      helperText={errors.company?.id ? errors.company.id.message : ""}
                      value={field.value.id}
                      onChange={(e) => {
                        const selectedCompany = companies.find((company) => company.id === e.target.value)!;
                        field.onChange({ id: selectedCompany.id, name: selectedCompany.companyName });

                        handleOnChangeCompany(e.target.value);
                      }}
                    >
                      {companies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.companyName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Divider className="my-2" />

              {/* 依頼者名 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="依頼者名" required />
                <Controller
                  name="userId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      select
                      error={!!errors.userId}
                      helperText={errors.userId ? errors.userId.message : ""}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    >
                      {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          {user.staffLastName + user.staffFirstName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Divider className="my-2" />

              {/* 建物名 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="建物名" required />
                <Controller
                  name="building"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      select
                      error={!!errors.building?.id}
                      helperText={errors.building?.id ? errors.building.id.message : ""}
                      value={field.value.id}
                      onChange={(e) => {
                        const selectedBuinding = pmBuildings.find((pmBuilding) => pmBuilding.id === e.target.value)!;
                        field.onChange({
                          id: selectedBuinding.id,
                          address: `${selectedBuinding.address.city}${selectedBuinding.address.town}${selectedBuinding.address.street}`,
                          autoLockNo: selectedBuinding.howToUnlockAutoLock,
                          name: selectedBuinding.name,
                          existsElevator: selectedBuinding.hasElevator,
                          isAutoLock: selectedBuinding.hasAutoLock,
                          state: selectedBuinding.address.prefecture,
                          type: selectedBuinding.type,
                        });

                        handleOnChangeBuilding(e.target.value);
                      }}
                    >
                      {pmBuildings.map((pmBuilding) => (
                        <MenuItem key={pmBuilding.id} value={pmBuilding.id}>
                          {pmBuilding.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Divider className="my-2" />

              {/* 部屋 */}
              {formData.building.type === "apartmentBuilding" && (
                <>
                  <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                    <ItemName itemName="部屋" required />
                    <Controller
                      name="unitName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          select
                          error={!!errors.unitName}
                          helperText={errors.unitName ? errors.unitName.message : ""}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        >
                          {pmUnits.map((pmUnit) => (
                            <MenuItem key={pmUnit.id} value={pmUnit.name}>
                              {pmUnit.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Box>
                  <Divider className="my-2" />
                </>
              )}

              {/* 鍵の置き場所 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="鍵の置き場所" required />
                <TextField
                  {...register("placeOfKey")}
                  size="small"
                  error={!!errors.placeOfKey}
                  helperText={errors.placeOfKey ? errors.placeOfKey.message : ""}
                />
              </Box>
              <Divider className="my-2" />

              {/* 退去立会代行 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="退去立会代行" required />
                <Controller
                  name="needFinalInspection"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} row onChange={(e) => field.onChange(e.target.value === "true")}>
                      <FormControlLabel
                        value="true"
                        control={<Radio className="text-remodela-green" />}
                        label="依頼する"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio className="text-remodela-green" />}
                        label="依頼しない"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  )}
                />

                <div />

                {formData.needFinalInspection && (
                  <Box>
                    <Box>
                      <p>立会日時</p>
                      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Controller
                          name="finalInspection.date"
                          control={control}
                          render={({ field }) => (
                            <DateTimePicker
                              {...field}
                              onChange={(value) => field.onChange(value)}
                              slotProps={{ textField: { size: "small" } }}
                              timeSteps={{ minutes: 15 }}
                              className="w-full"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>

                    <Box className="mt-2">
                      <p>入居者氏名</p>
                      <TextField {...register("finalInspection.residentName")} size="small" fullWidth />
                    </Box>

                    <Box className="mt-2">
                      <p>電話番号</p>
                      <TextField {...register("finalInspection.phoneNumber")} size="small" fullWidth />
                    </Box>

                    <Box className="mt-2">
                      <p>契約終了日</p>
                      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                        <Controller
                          name="finalInspection.leaseEndDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              onChange={(value) => field.onChange(value)}
                              slotProps={{ textField: { size: "small" } }}
                              className="w-full"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                )}
              </Box>
              <Divider className="my-2" />

              {/* 工事完了希望日 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="工事完了希望日" />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <Controller
                    name="dueDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        onChange={(value) => field.onChange(value)}
                        slotProps={{ textField: { size: "small" } }}
                        className="w-full"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Divider className="my-2" />

              {/* 無償利用が可能な駐車場の場所 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="無償利用が可能な駐車場の場所" />
                <TextField
                  {...register("placeOfParking")}
                  size="small"
                  error={!!errors.placeOfParking}
                  helperText={errors.placeOfParking ? errors.placeOfParking.message : ""}
                />
              </Box>
              <Divider className="my-2" />

              {/* その他ご要望 */}
              <Box className="py-2 grid grid-cols-1 gap-2 sm:grid-cols-custom sm:items-center sm:gap-4">
                <ItemName itemName="その他ご要望" />
                <TextareaAutosize
                  {...register("request")}
                  placeholder="クロス品番を指定したい（サンゲツ / SP-2111）"
                  className="whitespace-pre-line border-[1px] border-[#e0e0e0] rounded-lg mt-2 py-[8.5px] px-[14px] w-full"
                  minRows={2}
                />
              </Box>

              <Box className="flex justify-center">
                <Button
                  type="submit"
                  variant="contained"
                  className="bg-remodela-green font-bold rounded-lg w-40 h-12 mx-3"
                >
                  登録
                </Button>
              </Box>
            </Paper>
          </Box>
        </form>
      </MainLayout>
    </>
  );
};

interface ItemNameProps {
  itemName: string;
  required?: boolean;
}
const ItemName = ({ itemName, required }: ItemNameProps): JSX.Element => {
  return (
    <>
      <Box className="flex items-center">
        <div>{itemName}</div>
        {required ? (
          <div className="flex justify-center items-center font-bold rounded-md text-white bg-remodela-error text-xs w-8 h-6 ml-1">
            必須
          </div>
        ) : undefined}
      </Box>
    </>
  );
};
