import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useStore } from "@nanostores/react";

import { MainLayout } from "components/Layout/MainLayout";
import { CommentThread } from "components/CommentThread/CommentThread";
import { ProjectHeader } from "../components/ProjectHeader";
import { ImageUploadArea } from "../components/imageUploadArea";
import { ProjectDrop } from "../components/ProjectDrop";
import { ProjectBasicInfo } from "../components/projectBasicInfo";
import { getOnSnapshotProject } from "../useCases/onSnapshot/project";
import { ReviewsHistory } from "../components/ReviewsHistory";
import { getOnSnapshotBizEstimates } from "../useCases/onSnapshot/bizEstimates";
import { BizEstimate } from "@shared/types/entities/owner/BizEstimate";
import { Project } from "@shared/types/entities/owner/Project";

import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { fetchBizContractorByContractorId } from "repositories/owner/BizContractor";
import { updateBizContractorByAction } from "../api/updateBizContractorByAction";
import { toEntityFromDoc } from "utils/firebaseUtil";
import { BizEstimateContent } from "../components/bizEstimatesArea/BizEstimateContent";
import { NoEstimateContent } from "../components/bizEstimatesArea/NoEstimateContent";

export const ProjectDetail = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const [project, setProject] = useState<Project>();
  const [bizEstimates, setBizEstimates] = useState<BizEstimate[]>([]);
  const { bizContractorId } = useStore(BizConstractorStore.IDMap);

  useEffect(() => {
    const projectUnsubscribe = getOnSnapshotProject(companyId!, projectId!, (docSnapshot) => {
      setProject(toEntityFromDoc<Project>(docSnapshot));
    });

    const bizEstimatesUnsubscribe = getOnSnapshotBizEstimates(companyId!, projectId!, (querySnapshot) => {
      const bizEstimates = querySnapshot.docs.map((queryDocSnapshot) => {
        return toEntityFromDoc<BizEstimate>(queryDocSnapshot);
      });

      setBizEstimates(bizEstimates);
    });

    const updateBizContractorAction = async (): Promise<void> => {
      const bizContractor = await fetchBizContractorByContractorId(bizContractorId);

      if (!bizContractor.actions?.firstProjectOpen) {
        updateBizContractorByAction({
          companyId: companyId!,
          projectId: projectId!,
          bizContractorId,
          actionName: "firstProjectOpen",
        });
      }
    };

    updateBizContractorAction();

    return () => {
      projectUnsubscribe();
      bizEstimatesUnsubscribe();
    };
  }, [companyId, projectId, bizContractorId]);

  if (!project) {
    return <></>;
  }

  return (
    <>
      <MainLayout title="案件詳細">
        <ProjectHeader project={project} />

        <Box padding={1}>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <ProjectBasicInfo project={project} />
            </Grid>
            <Grid xs={12} md={8}>
              <ImageUploadArea project={project} />
            </Grid>

            <Grid xs={12}>
              {bizEstimates.length > 0 ? (
                <BizEstimateContent project={project} bizEstimates={bizEstimates} />
              ) : (
                <NoEstimateContent />
              )}
            </Grid>

            <Grid xs={12}>
              <ReviewsHistory project={project} />
            </Grid>

            <Grid xs={12}>
              <ProjectDrop project={project} />
            </Grid>
          </Grid>
        </Box>

        <CommentThread companyId={companyId!} projectId={projectId!} />
      </MainLayout>
    </>
  );
};
