import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Swiper, SwiperSlide } from "swiper/react";

import useCategorizedBizEstimates from "../../hooks/useCategorizedEstimates";
import { BizEstimate, GentyoImage } from "@shared/types/entities/owner/BizEstimate";
import { UpdateProjectByStatus } from "@shared/types/functionParams";
import { updateProjectByStatus } from "../../api/updateProjectByStatus";
import { useSnackbar } from "context/snackbarContext";
import { useStore } from "@nanostores/react";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { Project } from "@shared/types/entities/owner/Project";
import { useBackdrop } from "context/backdropContext";
import {
  getTotal,
  getSubtotal,
  getTax,
  getLandlordTotal,
  getTenantTotal,
  getReferralFeeTotal,
  getLandlordAmount,
  getTenantAmount,
  getReferralFee,
  getValidityPeriod,
} from "@shared/utils/estimates";
import { OwnerCommentTooltip } from "../OwnerCommentTooltip";
import { useProfitManagementAddOn } from "features/projectDetail/hooks/useProfitManagementAddOn";

interface EstimateContentProps {
  project: Project;
  bizEstimates: BizEstimate[];
}
export const BizEstimateContent = ({ project, bizEstimates }: EstimateContentProps): JSX.Element => {
  const [completeConstructionConfirmDialogOpen, setCompleteConstructionConfirmDialogOpen] = useState<boolean>(false);
  const [gentyoImageDialogOpen, setGentyoImageDialogOpen] = useState<boolean>(false);
  const [gentyoImages, setGentyoImages] = useState<GentyoImage[]>();

  const navigate = useNavigate();
  const { userId } = useStore(BizConstractorStore.IDMap);
  const { showSnackbar } = useSnackbar();
  const { categorizedEstimatesList } = useCategorizedBizEstimates(bizEstimates);
  const { isAddOnEnabled } = useProfitManagementAddOn(project.companyId);

  /**現調写真クリック */
  const handleClickGentyoImage = (gentyoImages?: GentyoImage[]): void => {
    if (!gentyoImages || gentyoImages.length === 0) {
      return;
    }

    setGentyoImages(gentyoImages);
    setGentyoImageDialogOpen(true);
  };

  /**現調写真モーダルを閉じる */
  const handleGentyoDialogClose = (): void => setGentyoImageDialogOpen(false);

  /**見積提案ボタン */
  const handleProposalEstimate = async (): Promise<void> => {
    const updateProjectByStatusRequestParams: UpdateProjectByStatus.RequestParams = {
      status: "オファー可能",
      companyId: project.companyId,
      projectId: project.id!,
      bizUserId: userId,
    };
    await updateProjectByStatus(updateProjectByStatusRequestParams);

    showSnackbar("見積を提案しました。", "success");
  };

  /**工事完了ボタン */
  const handleCompleteConstruction = (): void => {
    setCompleteConstructionConfirmDialogOpen(true);
  };

  /**工事完了確認モーダルを閉じる */
  const handleCompleteConstructionConfirmDialogClose = (): void => {
    setCompleteConstructionConfirmDialogOpen(false);
  };

  return (
    <>
      <Paper className="p-4">
        <Box className="flex items-center justify-between">
          <Box className="font-bold">見積内容</Box>

          <Box>
            {project.status === "見積中" && (
              <Button
                variant="contained"
                className="bg-remodela-green rounded-md font-bold"
                onClick={handleProposalEstimate}
              >
                見積を提案する
              </Button>
            )}

            {["見積中", "オファー可能"].includes(project.status) && (
              <Button
                variant="contained"
                className="bg-remodela-green rounded-md font-bold ml-5"
                onClick={() => {
                  navigate(`/companies/${project.companyId}/projects/${project.id}/estimates`);
                }}
              >
                見積を編集する
              </Button>
            )}

            {project.status === "工事中" && (
              <Button
                variant="contained"
                className="bg-remodela-green rounded-md font-bold"
                onClick={handleCompleteConstruction}
              >
                工事を完了する
              </Button>
            )}
          </Box>
        </Box>

        <Box>
          <Box className="flex flex-col sm:flex-row sm:items-center">
            <Box className="flex items-center">
              <Box className="text-lg sm:text-xl text-pink font-bold pr-1">
                {`¥${getTotal(bizEstimates, project.bizTaxType!).toLocaleString()}`}
              </Box>
              <Box className="flex text-xs sm:text-sm">
                <Box>
                  {`(税抜: ¥${getSubtotal(bizEstimates).toLocaleString()}`}
                  &nbsp;/&nbsp;
                  {`消費税: ¥${getTax(bizEstimates, project.bizTaxType!).toLocaleString()})`}
                </Box>
              </Box>
            </Box>
            <Box className="flex text-xs sm:text-sm sm:mx-2">
              {`有効期限: ${getValidityPeriod(project.bizEstimateValidityPeriod)}`}
            </Box>
          </Box>
          {isAddOnEnabled && (
            <Box className="flex ml-2">
              <Box>
                <Box className="flex items-center text-xs sm:text-sm">
                  <Box className="mr-2">賃貸人負担:</Box>
                  <Box className="text-sm sm:text-base text-pink font-bold">
                    {`¥${getLandlordTotal(bizEstimates, project.bizTaxType!).toLocaleString()}`}
                  </Box>
                </Box>
                <Box className="flex items-center text-xs sm:text-sm">
                  <Box className="mr-2">賃借人負担:</Box>
                  <Box className="text-sm sm:text-base text-pink font-bold">
                    {`¥${getTenantTotal(bizEstimates, project.bizTaxType!).toLocaleString()}`}
                  </Box>
                </Box>
              </Box>

              <Divider orientation="vertical" flexItem className="mx-2" />

              <Box>
                <Box className="flex items-center text-xs sm:text-sm">
                  <Box className="mr-2">紹介手数料:</Box>

                  <Box className="text-xs sm:text-sm">{`¥${getReferralFeeTotal(bizEstimates).toLocaleString()}`}</Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        {categorizedEstimatesList.map((categorizedEstimates, index) => {
          const categoryBoxStyle =
            categorizedEstimates.categoryName === "お値引き"
              ? "bg-lightPink text-remodela-error"
              : "bg-winterGreen text-remodela-green";

          return (
            <Box key={index} className="my-4">
              <Box className={`w-full font-bold p-3 ${categoryBoxStyle}`}>{categorizedEstimates.categoryName}</Box>

              <TableContainer component={Paper}>
                <Table size="small" className={`min-w-[1392px]`}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*2/24)] p-1">現調写真</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*4/24)] p-1">項目</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*1/24)] p-1">数量</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*1/24)] p-1">単位</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*1/24)] p-1">単価</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*2/24)] p-1">金額</TableCell>

                      {isAddOnEnabled && (
                        <>
                          <TableCell className="bg-lightGray text-center w-[calc(100%*2/24)] p-1">賃貸人負担</TableCell>
                          <TableCell className="bg-lightGray text-center w-[calc(100%*2/24)] p-1">賃借人負担</TableCell>
                          <TableCell className="bg-lightGray text-center w-[calc(100%*2/24)] p-1">紹介手数料</TableCell>
                        </>
                      )}
                      <TableCell className="bg-lightGray text-center w-[calc(100%*5/24)] p-1">備考</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*1/24)] p-1">コメント</TableCell>
                      <TableCell className="bg-lightGray text-center w-[calc(100%*1/24)] p-1">承認</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {categorizedEstimates.bizEstimates.map((bizEstimate) => {
                      const gentyoImageURLCount = bizEstimate.gentyoImages?.length || 0;

                      return (
                        <TableRow key={bizEstimate.id} className="even:bg-offWhite">
                          {/* 現調写真 */}
                          <TableCell
                            className={`relative text-center text-sm p-0 w-[calc(100%*2/24)] ${
                              gentyoImageURLCount > 0 ? "cursor-pointer" : ""
                            }`}
                            onClick={() => {
                              handleClickGentyoImage(bizEstimate.gentyoImages);
                            }}
                          >
                            <Box className="m-auto h-16 w-[130px]">
                              <img
                                src={
                                  bizEstimate.gentyoImages?.[0]?.URL || `${process.env.PUBLIC_URL}/images/noImage.png`
                                }
                                alt="noImage"
                                className="h-full w-full object-contain"
                              />
                              {gentyoImageURLCount >= 2 && (
                                <Box className="absolute bottom-0 right-5 bg-pink text-white font-bold px-1 rounded">
                                  {`+${gentyoImageURLCount - 1}`}
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                          {/* 項目 */}
                          <TableCell className="text-sm w-[calc(100%*4/24)]">{bizEstimate.itemName}</TableCell>
                          {/* 数量 */}
                          <TableCell className="text-right text-sm w-[calc(100%*1/24)]">
                            {bizEstimate.quantity.toLocaleString()}
                          </TableCell>
                          {/* 単位 */}
                          <TableCell className="text-right text-sm w-[calc(100%*1/24)]">{bizEstimate.unit}</TableCell>
                          {/* 単価 */}
                          <TableCell className="text-right text-sm w-[calc(100%*1/24)]">
                            {`¥${bizEstimate.unitPrice.toLocaleString()}`}
                          </TableCell>
                          {/* 金額 */}
                          <TableCell className="text-right text-sm w-[calc(100%*2/24)]">
                            {`¥${bizEstimate.amount.toLocaleString()}`}
                          </TableCell>
                          {isAddOnEnabled && (
                            <>
                              {/* 賃貸人負担 */}
                              <TableCell className="text-right text-sm w-[calc(100%*2/24)]">
                                <Box>{bizEstimate.shareRate.landlord}%</Box>
                                <Box>{`(¥${getLandlordAmount(bizEstimate).toLocaleString()})`}</Box>
                              </TableCell>
                              {/* 賃借人負担 */}
                              <TableCell className="text-right text-sm w-[calc(100%*2/24)]">
                                <Box>{bizEstimate.shareRate.tenant}%</Box>
                                <Box>{`(¥${getTenantAmount(bizEstimate).toLocaleString()})`}</Box>
                              </TableCell>
                              {/* 紹介手数料 */}
                              <TableCell className="text-right text-sm w-[calc(100%*2/24)]">
                                <Box>{bizEstimate.referralFeeRate}%</Box>
                                <Box>{`(¥${getReferralFee(bizEstimate).toLocaleString()})`}</Box>
                              </TableCell>
                            </>
                          )}

                          {/* 備考 */}
                          <TableCell className="w-[calc(100%*5/24)]">
                            <Box className="text-sm max-h-16 overflow-y-auto">{bizEstimate.note}</Box>
                          </TableCell>
                          {/* コメント */}
                          <TableCell className="text-center text-sm w-[calc(100%*1/24)]">
                            {bizEstimate.ownerComments && bizEstimate.ownerComments.length > 0 && (
                              <OwnerCommentTooltip ownerComments={bizEstimate.ownerComments} />
                            )}
                          </TableCell>
                          {/* 承認 */}
                          <TableCell className="text-center text-sm w-[calc(100%*1/24)]">
                            {bizEstimate.ownerFixed ? (
                              <Box className="font-bold text-base text-[#428C58]">済</Box>
                            ) : (
                              <Box className="font-bold text-base text-[#EC7988]">未</Box>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          );
        })}

        {project.status === "工事中" && (
          <Box className="flex items-center justify-center border-2 border-dashed border-mediumGray p-3 bg-white">
            <p className="text-sm">新たに見積を追加する場合</p>
            <Button
              variant="contained"
              className="bg-remodela-green rounded-md font-bold mx-4"
              onClick={() => {
                navigate(`/companies/${project.companyId}/projects/${project.id}/estimates`);
              }}
            >
              見積追加に進む
            </Button>
          </Box>
        )}
      </Paper>

      {gentyoImages && (
        <ZoomGentyoImageDialog
          open={gentyoImageDialogOpen}
          onClose={handleGentyoDialogClose}
          gentyoImages={gentyoImages}
        />
      )}

      <CompleteConstructionConfirmDialog
        open={completeConstructionConfirmDialogOpen}
        onClose={handleCompleteConstructionConfirmDialogClose}
        project={project}
      />
    </>
  );
};

type CompleteConstructionConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  project: Project;
};

const CompleteConstructionConfirmDialog = ({ open, onClose, project }: CompleteConstructionConfirmDialogProps) => {
  const { userId } = useStore(BizConstractorStore.IDMap);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  const handleComplete = async (): Promise<void> => {
    setBackdrop(true);

    const updateProjectByStatusRequestParams: UpdateProjectByStatus.RequestParams = {
      status: "検収待ち",
      companyId: project.companyId,
      projectId: project.id!,
      bizUserId: userId,
    };

    try {
      await updateProjectByStatus(updateProjectByStatusRequestParams);
      showSnackbar("工事を完了しました。", "success");
    } catch (e) {
      showSnackbar("エラーが発生しました", "error");
      console.log(e);
    }

    setBackdrop(false);
    onClose();
  };

  const hasBizAfterImage: boolean = Boolean(project.bizAfterImageURLs && project.bizAfterImageURLs.length);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <Box className="flex flex-col items-center p-3">
        <ErrorIcon className="text-5xl text-remodela-green" />
        <Box className="font-bold text-2xl">確認</Box>

        <Box className="flex flex-col items-center text-sm my-4">
          <Box>工事を完了します。よろしいですか？</Box>
          <Box>一度完了した場合、見積の追加をすることはできません。</Box>
          {!hasBizAfterImage && <Box className="text-remodela-error">※工事後写真が登録されていません。</Box>}
        </Box>

        <Box className="flex justify-around w-full">
          <Button
            variant="outlined"
            className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28"
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button variant="contained" className="font-bold bg-remodela-green w-28" onClick={handleComplete}>
            完了する
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

interface ZoomGentyoImageDialogProps {
  open: boolean;
  onClose: () => void;
  gentyoImages: GentyoImage[];
}
const ZoomGentyoImageDialog = ({ open, onClose, gentyoImages }: ZoomGentyoImageDialogProps): JSX.Element => {
  const [zoomGentyoImageURL, setZoomGentyoImageURL] = useState<string>();

  useEffect(() => {
    setZoomGentyoImageURL(gentyoImages[0].URL);
  }, [gentyoImages]);

  const handleClickSlide = (gentyoImageURL: string) => {
    setZoomGentyoImageURL(gentyoImageURL);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xl"
      PaperComponent={Paper}
      PaperProps={{ className: "rounded-2xl" }}
    >
      <DialogTitle className="flex justify-between items-center">
        <Box className="font-bold">現地調査写真</Box>
      </DialogTitle>

      <DialogContent>
        <Box className="w-[16rem] h-[12rem] sm:w-[40rem] sm:h-[30rem]">
          <img src={zoomGentyoImageURL} alt="gentyoImage" className="h-full w-full object-contain" />
        </Box>

        <Box className=" sm:w-[40rem] sm:h-32">
          <Swiper slidesPerView={4} spaceBetween={5} navigation className="h-full">
            {gentyoImages?.map((gentyoImage, index) => {
              return (
                <SwiperSlide key={index} className="h-28 my-2">
                  <Box
                    className="cursor-pointer h-full w-full border-[1px] border-mediumGray mb-3"
                    onClick={() => {
                      handleClickSlide(gentyoImage.URL);
                    }}
                  >
                    <img src={gentyoImage.URL} alt="" className="h-full w-full object-contain" />
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
