import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { FireTimestamp } from "./firebaseUtil";

export function getFormattedDate(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "yyyy-MM-dd");
}

export function getFormattedDateTime(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "yyyy-MM-dd HH:mm:ss");
}

export function getFormattedDateAtTime(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "HH:mm");
}

export function getFormattedDateWithDay(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "yyyy/MM/dd (EEE)", { locale: ja });
}
