import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Paper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import { MainLayout } from "components/Layout/MainLayout";
import { BuildingArea } from "../components/BuildingArea";
import { FilterMenu } from "../components/FilterMenu";
import { useBuildingInfoList } from "../hooks/useBuildingInfoList";
import { BuildingInfo } from "../useCases/fetchBuildingInfoList";

export const Buildings = (): JSX.Element => {
  const { loading, buildingNames, filterBuilding, filteredProjectCount, filteredBuildingInfoList } =
    useBuildingInfoList();

  return (
    <>
      <MainLayout title="案件一覧">
        <Box className="p-2">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <Box className="flex items-center">
                <SearchIcon />
                <p>検索条件</p>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FilterMenu buildingNames={buildingNames} filterBuilding={filterBuilding} />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box className="p-2">
          {loading ? (
            <ProjectsLoading />
          ) : (
            <ProjectList
              filteredProjectCount={filteredProjectCount}
              filteredBuildingInfoList={filteredBuildingInfoList}
            />
          )}
        </Box>
      </MainLayout>
    </>
  );
};

const ProjectsLoading = (): JSX.Element => {
  return (
    <Box className="flex items-center">
      <Box className="px-2">案件取得中</Box>
      <CircularProgress size="2rem" className="text-remodela-green" />
    </Box>
  );
};

interface ProjectListProps {
  filteredProjectCount: number;
  filteredBuildingInfoList: BuildingInfo[];
}
const ProjectList = ({ filteredProjectCount, filteredBuildingInfoList }: ProjectListProps): JSX.Element => {
  const hasProjects = filteredProjectCount > 0;

  return (
    <>
      <Box className="m-2 text-right">{`${filteredProjectCount}件を表示中`}</Box>
      <Paper elevation={3} className="p-2 mb-10">
        {hasProjects ? (
          filteredBuildingInfoList!.map((filteredBuildingInfo) => (
            <BuildingArea
              key={filteredBuildingInfo.buildingId}
              buildingId={filteredBuildingInfo.buildingId}
              buildingName={filteredBuildingInfo.buildingName}
              projectWithUsers={filteredBuildingInfo.projectWithUsers}
            />
          ))
        ) : (
          <Box className="text-center py-4">案件はありません</Box>
        )}
      </Paper>
    </>
  );
};
